import type { NextPageContext } from 'next';
import { ErrorLayout, ErrorProps, UnexpectedError } from '@honeycomb/ui';
import { DnataTravelLogo } from '../components/logos/DnataTravelLogo';
import { ErrorMasthead } from '../components/error/ErrorMasthead';
import { errorPageContent } from '../components/error/errorContent';

type Props = {
    error: ErrorProps;
    correlationId?: string;
};

export default function Error({ error, correlationId }: Props) {
    return <UnexpectedError error={error} correlationId={correlationId} contentOverrides={errorPageContent} />;
}

Error.getLayout = (page: React.ReactElement, props: Record<string, unknown>) => {
    return (
        <ErrorLayout logo={<DnataTravelLogo />} masthead={<ErrorMasthead />} {...props}>
            {page}
        </ErrorLayout>
    );
};

export const getServerSideProps = async ({ err, res, req }: NextPageContext) => {
    const statusCode = res?.statusCode ?? err?.statusCode ?? 404;
    return {
        props: {
            error: {
                statusCode,
                ...err,
            },
            correlationId: req?.headers['x-trp-correlation-id'],
        },
    };
};
